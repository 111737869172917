import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AddStudent from "./registerStudent/AddStudent";

const BottomNav = () => {
  const [value] = useState(0);
  const ref = useRef();
  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);
  return (
    <Box ref={ref}>
      {
        {
          // 0: <ClusterMap />,
          // 0: <Rooms />,
          0: <AddStudent />,
        }[value]
      }
      {/* <Paper
        elevation={3}
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
        >
          <BottomNavigationAction label="Map" icon={<LocationOn />} />
          <BottomNavigationAction label="Rooms" icon={<Bed />} />
          <BottomNavigationAction label="Add" icon={<AddLocationAlt />} />
        </BottomNavigation>
      </Paper> */}
    </Box>
  );
};

export default BottomNav;
