import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import React from "react";

export default function Success({ mobileNo }) {
  return (
    <>
      <Box>
        {" "}
        <Alert severity="success">
          <AlertTitle>
            Your application has been submitted successfully!
          </AlertTitle>

          <Typography>
            Thank you for choosing Perfect Institute of Technology, we will get
            in touch with you as soon as possible.
          </Typography>
        </Alert>
      </Box>
    </>
  );
}
