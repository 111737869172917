import { validateStudent } from "../actions/utils/validator";

const reducer = (state, action) => {
  switch (action.type) {
    case "START_LOADING":
      return { ...state, loading: true };
    case "END_LOADING":
      return { ...state, loading: false };
    case "UPDATE_USER":
      return { ...state, currentUser: action.payload };
    case "UPDATE_DETAILS":
      setItemOnLocalStorage(state.personalInfo, action, "personalInfo");
      return {
        ...state,
        personalInfo: { ...state.personalInfo, ...action.payload },
      };
    case "UPDATE_COURSEINFO":
      setItemOnLocalStorage(state.courseInfo, action, "courseInfo");
      return {
        ...state,
        courseInfo: { ...state.courseInfo, ...action.payload },
      };
    case "RESET_FORM":
      localStorage.clear();
      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          fullNames: "",
          email: "",
          gender: "",
          nationality: "",
          idNo: "",
          DOB: new Date().toISOString(),
          guardian: "",
          relationShip: "",
          tel: "",
        },
        currentForm: { index: 0 },
        courseInfo: {
          courseCode: "",
          courseName: "",
          courseLevel: "",
          sponsorship: "",
          nameOfSponsor: "",
          telOfSponsor: "",
          modeOfStudy: "",
        },
        educationBackground: {
          studyInfo: [],
          attachedCert: null,
          tc: "",
        },
        errors: [],
      };
    case "UPDATE_ROW":
      const updatedRow = action.payload.row;
      return {
        ...state,
        educationBackground: {
          ...state.educationBackground,
          studyInfo: state.educationBackground.studyInfo.map((row) =>
            row.id === action.payload.row.id ? updatedRow : row
          ),
        },
      };
    case "UPDATE_UPLOADS":
      return {
        ...state,
        educationBackground: {
          ...state.educationBackground,
          attachedCert: action.payload,
        },
      };
    case "DELETE_ROW":
      return {
        ...state,
        educationBackground: {
          ...state.educationBackground,
          studyInfo: state.educationBackground.studyInfo.filter(
            (row) => row.id !== action.payload.id
          ),
        },
      };
    case "UPDATE_ROWEMPTY":
      return {
        ...state,
        educationBackground: {
          ...state.educationBackground,
          studyInfo: [
            ...state.educationBackground.studyInfo,
            action.payload.row,
          ],
        },
      };
    case "UPDATE_ERRORS":
      const newErrors = newStateErrors(state, action);

      return {
        ...state,
        errors: [...newErrors, ...action.payload],
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: state.errors.filter(
          (error) => error.fieldName !== action.payload.name
        ),
      };
    case "NEXT_STEP":
      const { personalInfo, courseInfo, educationBackground, currentForm } =
        state;
      const index = currentForm.index;
      // const nextStep = action.payload.index;
      const data = [personalInfo, courseInfo, educationBackground];
      const joiErrors = validateStudent(data[index]);

      if (joiErrors !== null) {
        const newErrs = newStateErrors(state, { payload: joiErrors });
        return {
          ...state,
          errors: [...newErrs, ...joiErrors],
        };
      }
      setItemOnLocalStorage(state.currentForm, action, "currentForm");
      return {
        ...state,
        currentForm: { ...state.currentForm, ...action.payload },
      };
    // }

    case "PREV_STEP":
      localStorage.setItem(
        "currentForm",
        JSON.stringify({ index: state.currentForm.index - 1 })
      );
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          index: state.currentForm.index - 1,
        },
      };
    case "UPDATE_FORMINDEX":
      setItemOnLocalStorage(state.currentForm, action, "currentForm");
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          ...action.payload,
        },
      };
    case "UPDATE_ALERT":
      return { ...state, alert: action.payload };
    case "UPDATE_SUCCESS":
      return { ...state, success: action.payload };
    default:
      throw new Error("No matched action!");
  }
};

export default reducer;
const newStateErrors = (state, action) => {
  let newErrs = [...state.errors];
  action.payload.map((err) => {
    return (newErrs = newErrs.filter(
      (error) => error.fieldName !== err.fieldName
    ));
  });
  return newErrs;
};
const setItemOnLocalStorage = (item, action, storageName) => {
  localStorage.setItem(
    storageName,
    JSON.stringify({ ...item, ...action.payload })
  );
};
