import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Box,
  ClickAwayListener,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { useValue } from "../../../context/ContextProvider";
import { Send, UploadFileOutlined } from "@mui/icons-material";
// import { upload } from "../../../actions/student";
import { axiosReq } from "../../../axiosReq";
// import { axiosReq } from "../../../axiosReq";

const style = { display: "flex", gap: 2 };

function EditToolbar(props) {
  const { updateStateRow, setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    const newRow = {
      id,
      schAttended: "",
      yearFrom: "",
      yearTo: "",
      qualification: "",
      isNew: true,
    };
    updateStateRow(newRow);

    setRows((oldRows) => [...oldRows, newRow]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "schAttended" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add New Field
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
  updateStateRow: PropTypes.func.isRequired,
};

const AddEducationBg = ({ handleSubmit, showSubmit }) => {
  const {
    state: { educationBackground, personalInfo, courseInfo },
    dispatch,
  } = useValue();
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const [rows, setRows] = useState(educationBackground.studyInfo);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowMode, setRowMode] = useState();
  const [pageSize, setPageSize] = useState(5);
  // const [uploadedCert, setUploadedCert] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const url = process.env.REACT_APP_SERVER_PROURL + "/onlineApplication";

  const upload = async () => {
    const formData = new FormData();
    formData.append("attachedFile", educationBackground.attachedCert);
    const file = await axiosReq.post("/uploadFile/uploads", formData);
    // console.log(file.data.result);
    return file.data.result;
  };

  const formSubmit = async () => {
    // e.preventDefault();
    setDisableBtn(true);
    dispatch({ type: "START_LOADING" });
    try {
      const student = {
        ...courseInfo,
        ...personalInfo,
        studyInfo: [...educationBackground.studyInfo],
        docHeader: "onlineApplication",
        uploadedFile: "N/A",
      };
      const body = { body: JSON.stringify(student) };
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        ...body,
      });
      const data = await response.json();
      // console.log(data);
      if (!data.success) {
        throw new Error(data.message);
      }
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "success",
          message: "Submitted successfully",
        },
      });
      dispatch({
        type: "UPDATE_SUCCESS",
        payload: true,
      });
      dispatch({
        type: "RESET_FORM",
        payload: 0,
      });
      // return data.result;
    } catch (error) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: { open: true, severity: "error", message: error.message },
      });
      console.log(error);
      return null;
    }

    // }
    dispatch({ type: "END_LOADING" });
    // try {
    // const uploadedFileName = await upload();
    // const fileName = uploadedFileName ? uploadedFileName : "N/A";
    // console.log(fileName);
    // handleSubmit("N/A");
    // } catch (error) {
    //   console.log(error);
    // }
    // if (uploadedCert) {
    //   const formData = new FormData();
    //   formData.append("attachedFile", uploadedCert);
    //   const file = await axiosReq.post("/uploadFile/uploads", formData);
    //   const fileName = file.data.result;
    //   console.log(fileName);
    //   handleSubmit(fileName);
    // } else {
    //   handleSubmit("N/A");
    // }
  };
  const handleRemoveFile = () => {
    // setUploadedCert(null);
    dispatch({
      type: "UPDATE_UPLOADS",
      payload: null,
    });
  };
  const handleChange = (e) => {
    // const file = e.target.value;
    const allowedFile = new RegExp(/[^\s]+(.*?).(pdf)$/);
    // if (e.target.files[0]) {
    if (!allowedFile.test(e.target.files[0].name)) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Error: Only pdf file is allowed",
        },
      });
    } else {
      // setUploadedCert(e.target.files[0]);
      dispatch({
        type: "UPDATE_UPLOADS",
        payload: e.target.files[0],
      });
    }
    // }
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    dispatch({
      type: "DELETE_ROW",
      payload: {
        id: id,
      },
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    dispatch({
      type: "UPDATE_ROW",
      payload: { row: updatedRow },
    });
    return updatedRow;
  };
  useEffect(() => {
    setRows(educationBackground.studyInfo);
  }, [educationBackground]);
  const updateStateRow = (newR) => {
    dispatch({
      type: "UPDATE_ROWEMPTY",
      payload: {
        row: newR,
      },
    });
  };
  useEffect(() => {
    let row = {};
    Object.keys(rowModesModel).forEach((value) => {
      const newRow = rowModesModel[value];
      row = newRow;
    });
    setRowMode(row);
  }, [rowModesModel]);

  const columns = [
    {
      field: "schAttended",
      headerName: "School/College Attended",
      width: 200,
      editable: true,
    },
    {
      field: "yearFrom",
      headerName: "From (Year)",
      type: "number",
      width: 100,
      require,
      editable: true,
    },
    {
      field: "yearTo",
      headerName: "To (Year)",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "qualification",
      headerName: "Qualification",
      // type: "date",
      width: 220,
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <IconButton>
                  <SaveIcon sx={{ fontSize: { xs: 16, md: 22 } }} />

                  <Typography
                    sx={{ display: { xs: "flex", md: "none" }, fontSize: 12 }}>
                    SAVE
                  </Typography>
                </IconButton>
              }
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    // <Box
    //   sx={{
    // height: "100%",
    // alignItems: "center",
    // justifyContent: "center",
    //   width: "100%",
    // }}>
    <Box
      sx={{
        // height: 200,
        display: "flex",
        flexDirection: "column",
        gap: 5,
        ml: { md: 15, xs: "none" },
        mr: { md: 15, xs: "none" },
        // maxHeight: "20%",
        // width: "75%",
        "& .actions": {
          color: "secondary",
        },
        "& .textPrimary": {
          color: "primary",
        },
      }}>
      <ClickAwayListener
        onClickAway={() =>
          rowMode &&
          rowMode.mode === "edit" &&
          alert("please save the table first")
        }>
        <DataGrid
          autoHeight
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: { updateStateRow, setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </ClickAwayListener>
      {/* </Box> */}
      {/* <form encType="multipart/form-data"> */}
      {/* <form> */}
      <Box mb={5}>
        <p>
          <b>
            {" "}
            NB: Please upload certified merged copies of the relevant
            certificates (pdf *)
          </b>{" "}
        </p>

        <label htmlFor="uploadsCert">
          <input
            accept="application/pdf,.pdf"
            id="uploadsCert"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleChange(e)}
          />
          <UploadFileOutlined />{" "}
          {educationBackground.attachedCert ? (
            <Chip
              // icon={<CancelIcon />}
              label={educationBackground.attachedCert.name}
              // onDelete={}
              sx={{ flexWrap: "noWrap", width: "250px" }}
            />
          ) : (
            "Upload document"
          )}
        </label>
        {educationBackground.attachedCert && (
          <IconButton title="Remove file" onClick={handleRemoveFile}>
            <CancelIcon />
          </IconButton>
        )}
      </Box>
      <Box sx={{ mr: { md: 30, xs: 2 }, mb: 3 }}>
        <Typography fontWeight="bold">TERMS AND CONDITIONS</Typography>
        <Typography fontWeight="bold">I. ADMISSION </Typography>
        <Typography sx={style}>
          {" "}
          <span>(a) </span>{" "}
          <span>On admission, the student should provide the following</span>
        </Typography>
        <Typography sx={{ display: "flex", gap: 3 }}>
          <span>i. </span>
          <span> 2 copies of colored recent passport photographs.</span>
        </Typography>
        <Typography sx={style}>
          {" "}
          <span>ii. </span> <span>Copy of national ID</span>
        </Typography>
        <Typography sx={style}>
          {" "}
          <span>iii. </span>{" "}
          <span>Copies of previous certificates acquired.</span>
        </Typography>
        <Typography sx={style}>
          {" "}
          <span>iv.</span>
          <span>
            Registration Fee of<b> Kshs. 500</b> for short courses,
            <b> Kshs. 1000</b> for long courses.
          </span>
        </Typography>
        <br />
        <Typography fontWeight="bold">II. FEES</Typography>

        <Typography sx={style}>
          <span> (a) </span>
          <span>
            On admission, a student must pay <b>50% or above</b> of the term’s
            fee, and the balance to balance to be cleared in 2 installments.
          </span>
        </Typography>
        <Typography sx={style}>
          <span>(b)</span>{" "}
          <span>
            Payments should be made through the bank or mobile money{" "}
            <b>(M-Pesa) ONLY.</b>
          </span>
        </Typography>
        <Typography sx={style}>
          {" "}
          <span>(c)</span>{" "}
          <span>
            No student will be allowed to sit for exams without clearing Fees.
          </span>
        </Typography>
        <br />
        <br />
        {showSubmit && (
          <Button
            // type="submit"
            variant="contained"
            title="Submit form"
            disabled={!showSubmit || disableBtn}
            endIcon={<Send />}
            onClick={() => formSubmit()}>
            Submit
          </Button>
        )}
      </Box>

      {/* </form> */}
    </Box>
  );
};

export default AddEducationBg;
