import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "./reducer";

const initialState = {
  currentUser: null,
  success: false,
  regNo: new Date().getTime(),
  courses: [
    { name: "Diploma in Community Health and Development", code: "DCHD" },
    { name: "Diploma in Social Work and Community Development", code: "DSWCD" },
    { name: "Diploma in Counseling, Guiding and Psychology", code: "DCGP" },
    {
      name: "Diploma in Development Studies with Entrepreneurship Skills",
      code: "DDSES",
    },
    {
      name: "Certificate in Community Health and Development",
      code: "CCHD",
    },
    {
      name: "Certificate in Social Work and Community Development ",
      code: "CSWCD",
    },
    {
      name: "Certificate in Counseling, Guiding and Psychology ",
      code: "CCGP",
    },
    {
      name: "Certificate in Development Studies with Entrepreneurship Skills",
      code: "CDSES",
    },
    { name: "Diploma in Information Communication Technology ", code: "DICT" },
    { name: "Diploma in Information Technology	", code: "DIT" },
    { name: "Diploma in Journalism Media", code: "DJM" },
    { name: " Diploma in Broadcasting Journalism & Media", code: "DBJM" },
    {
      name: "Certificate in Information Communication Technology",
      code: "CICT",
    },
    { name: "Certificate in Information Technology ", code: "DIT" },
    { name: " Certificate in Journalism and Media Broadcasting", code: "CJMB" },
    { name: " Certificate in Journalism Radio and TV ", code: "CJRTV" },
    { name: "Certificate in Photography", code: "CP" },
    { name: "Certificate in Broadcasting Journalism & Media", code: "CBJM" },
    { name: " Artisan in Secretarial Studies", code: "ASS" },
    { name: " Artisan in Storekeeping", code: "ASK" },
    { name: " Artisan in Salesmanship", code: "AS" },
    { name: " Certificate in Business Management", code: "CBM" },
    { name: " Certificate in Human Resource Management", code: "CHRM" },
    { name: " Certificate in Supply Chain Management", code: "CSCM" },
    { name: " Certificate in Accountancy", code: " CA" },
    { name: " Certificate in Sales and Marketing", code: "CSM" },
    { name: " Certificate in Computerized Secretarial Studies", code: "CCSS" },
    { name: " Certificate in Banking and Finance", code: "CBF" },
    { name: " Certificate in Project Management", code: "CPM" },
    { name: " Certificate in Entrepreneurship Development", code: "CED" },
    { name: " Certificate in Clerical Operations", code: "CCO" },
    { name: " Diploma in Business Management", code: "DBM" },
    { name: " Diploma in Human Resource Management", code: "DHRM" },
    { name: " Diploma in Entrepreneurship Development", code: "DED" },
    { name: " Diploma in Project Management", code: "DPM" },
    { name: " Diploma in Banking and Finance", code: "DBF" },
    { name: " Diploma in Computerized Secretarial Studies", code: "DCSS" },
    { name: " Diploma in Sales and Marketing", code: "DSM" },
    { name: " Diploma in Accountancy", code: "DA" },
    { name: " Diploma in Supply Chain Management", code: "DSCM" },
    { name: " Diploma in Business Administration", code: "DBA" },
    { name: " Certified Public Accountants", code: "CPA" },
    { name: " Certified Secretaries", code: "CS" },
    { name: " Certified Information Communication Technology", code: "CICT" },
    { name: " Accounting Technicians Diploma", code: "ATD" },
    { name: " Diploma in Information Communication Technology", code: "DICT" },
    { name: " Diploma in Credit Management", code: "DCM" },
    {
      name: " Certificate in Accounting and Management Skills ",
      code: "CAMS",
    },
    { name: " Computer Packages	", code: "CP" },
    { name: " Accounting Packages(QuickBooks, Pastel, Sage)", code: "APS" },
    {
      name: " Graphic Design (Corel Draw, Photoshop Adobe Illustrator) ",
      code: "GD",
    },
    { name: " Video Editing and Photography	", code: "VEP" },
  ],
  loading: false,
  personalInfo: {
    fullNames: "",
    mobileNo: "",
    email: "",
    gender: "",
    nationality: "",
    idNo: "",
    DOB: new Date().toISOString(),
    guardian: "",
    relationShip: "",
    tel: "",
  },
  currentForm: { index: 0 },
  courseInfo: {
    courseCode: "",
    courseName: "",
    courseLevel: "",
    sponsorship: "",
    nameOfSponsor: "",
    telOfSponsor: "",
    modeOfStudy: "",
  },
  educationBackground: {
    studyInfo: [],
    attachedCert: null,
    tc: "",
  },
  alert: { open: false, severity: "info", message: "" },
  errors: [],
};

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const personalInfo = JSON.parse(localStorage.getItem("personalInfo"));
    const courseInfo = JSON.parse(localStorage.getItem("courseInfo"));
    const currentForm = JSON.parse(localStorage.getItem("currentForm"));
    if (personalInfo) {
      dispatch({ type: "UPDATE_DETAILS", payload: personalInfo });
    }
    if (courseInfo) {
      dispatch({ type: "UPDATE_COURSEINFO", payload: courseInfo });
    }
    if (currentForm) {
      dispatch({ type: "UPDATE_FORMINDEX", payload: currentForm });
    }
  }, []);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default ContextProvider;
