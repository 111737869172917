import axios from "axios";

// export const axiosReq = axios.create({
//   baseURL: process.env.REACT_APP_SERVER_PROURL1,
//   withCredentials: true,
// });

export const axiosReq = axios.create({
  baseURL: process.env.REACT_APP_SERVER_PROURL,
  withCredentials: true,
  // headers: { "Content-Type": "multipart/form-data" },
});
