import {
  Cancel,
  HomeOutlined,
  RestartAltOutlined,
  Send,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Stack,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useValue } from "../../context/ContextProvider";
import AddEducationBg from "./addEducationBg/AddEducationBg";
import AddPersonalInfo from "./addPersonalInfo/AddPersonalInfo";
import AddCourse from "./addCourseDetails/AddCourse";
import { validateStudent } from "../../actions/utils/validator";
import { submitStudent, upload } from "../../actions/student";
import Success from "./success/success";

const AddStudent = () => {
  const {
    state: {
      personalInfo,
      courseInfo,
      currentForm,
      success,
      educationBackground: { studyInfo, attachedCert },
    },
    dispatch,
  } = useValue();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([
    { label: "Personal Information", completed: false, visited: true },
    { label: "Course Applied", completed: false, visited: false },
    { label: "Education Background", completed: false, visited: false },
    // { label: "Confirmation", completed: false, visited: false },
  ]);
  const [showSubmit, setShowSubmit] = useState(false);
  const ref = useRef();
  const handleNext = () => {
    let nextStep;
    if (activeStep < steps.length - 1) {
      nextStep = activeStep + 1;
    } else {
      const stepIndex = findUnfinished();
      nextStep = stepIndex;
    }
    dispatch({
      type: "NEXT_STEP",
      payload: { index: nextStep },
    });
  };
  const checkEmptyFields = (obj, index) => {
    const err = validateStudent(obj);

    if (err !== null) {
      if (steps[index].completed) setComplete(index, false);
    } else {
      if (!steps[index].completed) setComplete(index, true);
    }
  };
  const checkDisabled = () => {
    if (success) return true;
    if (activeStep < steps.length - 1) return false;
    const index = findUnfinished();
    if (index !== -1) return false;

    return true;
  };
  const findUnfinished = () => {
    return steps.findIndex((step) => !step.completed);
  };

  useEffect(() => {
    setActiveStep(currentForm.index);
    steps[currentForm.index].visited = true;
    if (currentForm.index === 2) steps[2].completed = true;
  }, [currentForm]);
  useEffect(() => {
    checkEmptyFields(personalInfo, 0);
  }, [personalInfo]);
  useEffect(() => {
    checkEmptyFields(courseInfo, 1);
  }, [courseInfo]);
  const setComplete = (index, status) => {
    setSteps((steps) => {
      steps[index].completed = status;
      return [...steps];
    });
  };
  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
    if (findUnfinished() === -1 && currentForm.index === 2) {
      if (!showSubmit) setShowSubmit(true);
    } else {
      if (showSubmit) setShowSubmit(false);
    }
  }, [currentForm, activeStep]);

  const handleSubmit = async (fileName) => {
    // dispatch({ type: "START_LOADING" });
    try {
      // let fileName = "N/A";
      // if (attachedCert) fileName = await upload(attachedCert);
      // console.log(fileName);
      const student = {
        ...courseInfo,
        ...personalInfo,
        studyInfo: [...studyInfo],
        docHeader: "onlineApplication",
        uploadedFile: fileName,
      };
      await submitStudent(student, dispatch);
    } catch (error) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Oop something went wrong please try again!",
        },
      });
      dispatch({ type: "END_LOADING" });
      return error;
    }
  };

  const handleCancel = () => {
    if (success) {
      dispatch({
        type: "UPDATE_SUCCESS",
        payload: false,
      });
      window.location.reload();
    } else {
      dispatch({ type: "RESET_FORM", payload: 0 });
    }

    steps[2].completed = false;
  };
  const updateFormIndex = (newIndex) => {
    dispatch({ type: "UPDATE_FORMINDEX", payload: { index: newIndex } });
  };
  return (
    <Container sx={{ my: 4 }}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        sx={{
          mb: 3,
          // position: "sticky",
          // top: "18%",
          // background: "white",
          // opacity: 50,
          // zIndex: 999,
        }}>
        {steps.map((step, index) => (
          <Step key={step.label} completed={step.completed || success}>
            <StepButton
              onClick={() =>
                (step.visited || step.completed) &&
                !success &&
                updateFormIndex(index)
              }>
              {step.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box
        ref={ref}
        // sx={{py: 14 }}
      >
        {!success ? (
          {
            0: <AddPersonalInfo />,
            1: <AddCourse />,
            2: (
              <AddEducationBg
                handleSubmit={handleSubmit}
                showSubmit={showSubmit}
              />
            ),
          }[activeStep]
        ) : (
          <Success mobileNo={personalInfo.mobileNo} />
        )}

        <Stack
          direction="row"
          sx={{ pt: 2, mb: 2, justifyContent: "space-around" }}>
          {!success && (
            <Button
              color="inherit"
              disabled={!activeStep}
              onClick={() =>
                dispatch({
                  type: "PREV_STEP",
                  payload: {},
                })
              }>
              Back
            </Button>
          )}

          {!showSubmit && !success && (
            <Button disabled={checkDisabled()} onClick={handleNext}>
              Next
            </Button>
          )}
        </Stack>
        {!showSubmit && (
          <Stack
            sx={{ alignItems: "center", justifyContent: "space-around" }}
            direction="row">
            <Button
              variant="outlined"
              endIcon={success ? <RestartAltOutlined /> : <Cancel />}
              onClick={handleCancel}>
              {success ? "Restart Form" : "Cancel"}
            </Button>
            {success && (
              <Button
                variant="contained"
                endIcon={success ? <HomeOutlined /> : <Send />}
                onClick={() =>
                  success
                    ? window.location.replace("https://pitps.co.ke/")
                    : handleSubmit()
                }>
                {success ? "Return Home" : "Submit"}
              </Button>
            )}
          </Stack>
        )}
      </Box>
    </Container>
  );
};

export default AddStudent;
