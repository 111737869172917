import { Box, Stack, Autocomplete, TextField } from "@mui/material";
import { useValue } from "../../../context/ContextProvider";
import InfoField from "../addPersonalInfo/InfoField";
import { validateStudent } from "../../../actions/utils/validator";
import { useEffect, useState } from "react";

const AddCourse = () => {
  const [err, setErr] = useState(null);
  const {
    state: {
      courses,
      errors,
      courseInfo: {
        courseName,
        courseCode,
        courseLevel,
        sponsorship,
        nameOfSponsor,
        telOfSponsor,
        modeOfStudy,
      },
    },
    dispatch,
  } = useValue();

  const handleCourseFieldChange = (course) => {
    const tempCourseName = course ? course.name : "";
    const tempcourseCode = course ? course.code : "";
    const values = [
      { courseName: `${tempCourseName}` },
      { courseCode: `${tempcourseCode}` },
    ];
    for (let index = 0; index < values.length; index++) {
      const name = index === 0 ? "courseName" : "courseCode";
      validateStudent(values[index], dispatch, name);
    }

    dispatch({
      type: "UPDATE_COURSEINFO",
      payload: { courseName: tempCourseName, courseCode: `${tempcourseCode}` },
    });
  };
  useEffect(() => {
    const new_error = errors.filter((err) => err.fieldName === "courseName");
    if (new_error.length !== 0) {
      setErr(...new_error);
    } else {
      setErr(null);
    }
  }, [errors]);
  return (
    <Stack
      sx={{
        // height: windowSize - 935,
        alignItems: "center",
        // overflow: "auto",
        "& .MuiTextField-root": { width: "100%", maxWidth: 400, m: 1 },
      }}>
      <Box display="flex" flexDirection="column">
        <Autocomplete
          // disablePortal
          id="combo-box-demo"
          onChange={(event, value) => handleCourseFieldChange(value)}
          options={courses}
          defaultValue={courseName}
          getOptionLabel={(option) => (option.name ? option.name : courseName)}
          // sx={{ width: 400 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Course Applied"
              error={err && err.fieldName === "courseName"}
              helperText={err && err.fieldName === "courseName" && err.message}
            />
          )}
        />
        <InfoField
          mainProps={{
            name: "courseCode",
            label: "Course Code",
            value: courseCode,
          }}
          optionalProps={{ disabled: true, type: "text" }}
          minLength={5}
        />
        <InfoField
          mainProps={{
            name: "courseLevel",
            value: courseLevel,
          }}
          formLabel="Course Level"
          radioBtn={[
            { value: "artisan", label: "Artisan" },
            { value: "certificate", label: "Certificate" },
            { value: "diploma", label: "Diploma" },
            { value: "shortCourse", label: "Short Course" },
          ]}
        />
        <InfoField
          mainProps={{
            name: "sponsorship",
            value: sponsorship,
          }}
          formLabel="SponsorShip"
          radioBtn={[
            { value: "self", label: "Self Sponsored" },
            { value: "parent", label: "Parent / Guardian" },
            { value: "organization", label: "Organization" },
          ]}
        />
        {(sponsorship === "parent" || sponsorship === "organization") && (
          <>
            <InfoField
              mainProps={{
                name: "nameOfSponsor",
                label: "Name Of Sponsor",
                value: nameOfSponsor,
              }}
              // minLength={6}
            />
            <InfoField
              mainProps={{
                name: "telOfSponsor",
                label: "Sponsor/Organization Telephone",
                value: telOfSponsor,
              }}
              minLength={10}
              optionalProps={{ type: "number", max: 10 }}
            />
          </>
        )}
        <InfoField
          mainProps={{
            name: "modeOfStudy",
            value: modeOfStudy,
          }}
          formLabel="Mode Of Study"
          radioBtn={[
            { value: "morning", label: "Morning" },
            { value: "fullTime", label: "fullTime" },
            { value: "evening", label: "Evening" },
            { value: "online", label: "Online" },
          ]}
        />
      </Box>
    </Stack>
  );
};

export default AddCourse;
