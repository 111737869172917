// import fetchData from "./utils/fetchData";
import { axiosReq } from "../axiosReq";
// const url = process.env.REACT_APP_SERVER_PROURL;
const url = process.env.REACT_APP_SERVER_PROURL + "/onlineApplication";
export const upload = async (attachedFile) => {
  try {
    const formData = new FormData();
    formData.append("attachedFile", attachedFile);
    const file = await axiosReq.post("/uploadFile/uploads", formData);
    console.log(file.data.result);
    return file.data.result;
  } catch (err) {
    console.log(err);
  }
};
export const submitStudent = async (student, dispatch) => {
  try {
    const body = { body: JSON.stringify(student) };
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      ...body,
    });
    const data = await response.json();
    // console.log(data);
    if (!data.success) {
      throw new Error(response.data.message);
    }
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: "Submitted successfully",
      },
    });
    dispatch({
      type: "UPDATE_SUCCESS",
      payload: true,
    });
    dispatch({
      type: "RESET_FORM",
      payload: 0,
    });
  } catch (error) {
    dispatch({
      type: "UPDATE_ALERT",
      payload: { open: true, severity: "error", message: error.message },
    });
    console.log(error);
    return null;
  }

  // }
  dispatch({ type: "END_LOADING" });
};
