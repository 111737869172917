import { Box, TextField } from "@mui/material";
import { useValue } from "../../../context/ContextProvider";
import InfoField from "./InfoField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { validateStudent } from "../../../actions/utils/validator";
import moment from "moment/moment";

const AddPersonalInfo = () => {
  const {
    state: {
      personalInfo: {
        fullNames,
        mobileNo,
        email,
        gender,
        nationality,
        idNo,
        guardian,
        DOB,
        relationShip,
        tel,
      },
      errors,
    },
    dispatch,
  } = useValue();
  const [err, setErr] = useState(null);
  const handleDateChange = (newValue) => {
    dispatch({
      type: "UPDATE_DETAILS",
      payload: { DOB: newValue.$d },
    });
    validateStudent({ DOB: newValue.$d }, dispatch, "DOB");
  };

  useEffect(() => {
    const new_error = errors.filter((err) => err.fieldName === "DOB");
    if (new_error.length !== 0) {
      setErr(...new_error);
    } else {
      setErr(null);
    }
  }, [errors]);

  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        // height: windowSize - 935,
        "& .MuiTextField-root": { maxWidth: { md: "none", xs: 400 }, mb: 2 },
        // pt: 4,
        display: { md: "flex" },
        gap: { md: 2 },
        // overflow: "auto",
      }}>
      <Box
        display="flex"
        // flexDirection="column"
        sx={{
          ml: { md: "15%" },
          width: "100%",
          height: { md: "100%" },
          flexDirection: "column",
        }}>
        <InfoField
          mainProps={{
            name: "fullNames",
            label: "Full Names",
            value: fullNames,
          }}
          minLength={5}
        />
        <InfoField
          mainProps={{
            name: "mobileNo",
            label: "Mobile Number",
            value: mobileNo,
          }}
          minLength={10}
          optionalProps={{ type: "number" }}
        />
        <InfoField
          mainProps={{
            name: "email",
            label: "Email (optional)",
            value: email,
          }}
          // minLength={10}
          optionalProps={{ type: "email" }}
        />
        <InfoField
          mainProps={{
            name: "gender",
            value: gender,
          }}
          formLabel="Gender"
          radioBtn={[
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
          ]}
        />
        <InfoField
          mainProps={{
            name: "nationality",
            label: "Nationality",
            value: nationality,
          }}
          // minLength={6}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ mr: { md: "15%" }, width: "100%", height: { md: "100%" } }}>
        <InfoField
          mainProps={{
            name: "idNo",
            label: "ID/Passport Number",
            value: idNo,
          }}
          minLength={10}
          optionalProps={{ type: "number", max: 10 }}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <>
            <DesktopDatePicker
              // disableFuture
              label="Date Of Birth"
              openTo="year"
              format="DD/MM/YYYY"
              inputFormat="DD/MM/YYYY"
              views={["year", "month", "day"]}
              name="DOB"
              value={dayjs(DOB)}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  error: err && err.fieldName === "DOB",
                  helperText: err && err.fieldName === "DOB" && err.message,
                },
              }}
              renderInput={(params) => <TextField {...params} />}
              sx={{ display: { xs: "none", md: "flex" } }}
            />
            <MobileDatePicker
              // disableFuture
              label="Date Of Birth"
              inputFormat="DD/MM/YYYY"
              openTo="year"
              format="DD/MM/YYYY"
              views={["year", "month", "day"]}
              value={dayjs(DOB)}
              name="DOB"
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  error: err && err.fieldName === "DOB",
                  helperText: err && err.fieldName === "DOB" && err.message,
                },
              }}
              renderInput={(params) => <TextField {...params} />}
              sx={{ display: { xs: "flex", md: "none" } }}
            />
          </>
        </LocalizationProvider>
        <InfoField
          mainProps={{
            name: "guardian",
            label: "Guardian/Kin Full Names",
            value: guardian,
          }}
          minLength={5}
        />
        <InfoField
          mainProps={{
            name: "relationShip",
            label: "Relationship",
            value: relationShip,
          }}
        />
        <InfoField
          mainProps={{
            name: "tel",
            label: "Guardian/Kin Mobile Number",
            value: tel,
          }}
          minLength={10}
          optionalProps={{ type: "number", max: 10 }}
        />
      </Box>
    </Box>
  );
};

export default AddPersonalInfo;
