import React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import photoURL from "../pitpsLogo.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "rgb(182,227,212)",
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});


const NavBar = () => {


  return (
    <ThemeProvider theme={theme}>
      <AppBar color="primary">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box sx={{ mr: 1 }}>
              <Avatar
                sx={{ width: 96, height: 56 }}
                src={photoURL}
                alt="pitps offical logo"
                variant="square"
              />
            </Box>
            <Typography
              variant="h6"
              component="h1"
              noWrap
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              Perfect Institute Of Technology
            </Typography>
            <Typography
              variant="h6"
              component="h1"
              noWrap
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              PITPS
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </ThemeProvider>
  );
};

export default NavBar;
