import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
// import Home from "./components/home";
import BottomNav from "./components/BottomNav";
import Notification from "./components/Notification";
import Loading from "./components/Loading";

const App = () => {
  return (
    <>
      <Loading />
      <Notification />
      <NavBar />
      <BrowserRouter>
        <Routes>
          {/* <Route path="register/*" element={<BottomNav />} /> */}
          <Route path="*" element={<BottomNav />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
